import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxEncryptCookieService } from 'ngx-encrypt-cookie';
import { ConsoleService } from '../console/console.service';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  environment = environment;
  key;
  keysArray = new Map();
  constructor(
    private _cookieService : CookieService,
    private _ngxCookieService : NgxEncryptCookieService,
    private _consol : ConsoleService
    ) {
      this.key = "789787987";
     }

  /** Renvoie true si l'utilisateur est sur mobile  **/
  checkIsMobile(){
    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || false;
    return isMobile;
  }

//COOKIE HANDLER

  /* GETTER */
  get(name:string){
    name = this.encryptUsingAES256(name);
    return this._ngxCookieService.get(name, true, this.key);
  }
  /* FIN GETTER */

  /* SETTER */
  set(name:string, data, path = null, domain = null){
    let tmp = name;
    name = this.encryptUsingAES256(name);
    this.keysArray.set(tmp, name);
    this._ngxCookieService.set(name, data, true, this.key, 90, "/", environment.domain);
  }
  /* FIN SETTER */

  /* CHECK */
  check(name:string){
    return this._ngxCookieService.check(name);
    // return this._cookieService.check(name);
  }
  /* FIN CHECK */

  /* GETALL */
  getAll(name:string){
    return this._ngxCookieService.getAll(this.key);

  }
  /* FIN GETALL */

  /* CHECKLIST FROM AN ARRAY */
  checkList(list: []){
    let response = [];
    list.forEach(element => {
      response.push(this.check(element));
    })
    return response;
  }
  /* FIN CHECKLIST FROM AN ARRAY */

  /* CLEAR */
  clear(){
    return this._cookieService.deleteAll('/', environment.domain);
  }
  /* FIN CLEAR */


  convertText(name, conversion:string = 'encrypt') {
    if(!name) return;
    if (conversion=="encrypt") {
      return CryptoJS.AES.encrypt(name.trim(), this.key.trim()).toString();
    }
    else {
      return CryptoJS.AES.decrypt(name.trim(), this.key.trim()).toString(CryptoJS.enc.Utf8);
    }
  }
  encryptUsingAES256(name) {
    if(!name) return;
    let _key = CryptoJS.enc.Utf8.parse(this.key);
    let _iv = CryptoJS.enc.Utf8.parse(this.key);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(name), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypted.toString();
  }
}
