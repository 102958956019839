<h6 class="event-title">
  {{ tnsService.getEventTitle(event.type) }}

  <!-- <p-tag [severity]="'danger'" value="REJETÉ" *ngIf="event.state == 'KO'" class="float-end"/> -->
  <!-- <p-tag [severity]="'success'" value="VALIDÉ" *ngIf="event.state != 'KO'" class="float-end" /> -->
</h6>
<span class="event-date">{{ event.date | date:'dd/MM/yyyy HH:mm:ss' }}</span>
<div class="event-description" [ngSwitch]="event.type">
  <div class="p-2" *ngSwitchCase="'CREATION'">OUVERTURE</div>
  <div class="p-2" *ngSwitchCase="'CLIENT_FILE_ACCESSED'">Accès au parcours par {{ customer.firstname }} {{ customer.lastname }}</div>
  <div class="p-2" *ngSwitchCase="'GDPR_ACCEPTATION'">Accord de consentement RGPD par {{ customer.firstname }} {{ customer.lastname }}</div>
  <div *ngSwitchCase="'DOCUMENT_SUBMISSION'">
    <div class="border-bottom-2 border-light p-2">
      Identification <b>{{ event.documentType }}</b> par {{ customer.firstname }} {{ customer.lastname }} <br/>
      le <b>{{ event.date | date:'dd/MM/yyyy HH:mm:ss' }}</b>
    </div>
    <div class="p-2 border-bottom-2 border-light" *ngFor="let error of event.errors">
      <p-tag [severity]="'danger'" value="{{ 'Erreur : ' + error }}" />
      <p class="m-0 mt-3">{{ tnsService.getErrorDescription(error) }}</p>
    </div>
    <div class="p-2 border-bottom-2 border-light" *ngFor="let warning of event.warnings">
      <p-tag [severity]="'warning'" value="{{ 'Avertissement : ' + warning }}" />
      <p class="m-0 mt-3">{{ tnsService.getWarningDescription(warning) }}</p>
    </div>
  </div>
  <div class="p-2" *ngSwitchCase="'UPDATE'">
    Mise à jour du dossier
  </div>
  <div class="p-2" *ngSwitchCase="'DOCUMENT_REVIEWED'">
    {{ !event.review.acceptedByOperator ? 'Rejet du dossier' : 'Acceptation forcée' }} par <b>{{ event.channel == "SIGNBOOK" ? 'Trust & Sign' : event.operator.email }}</b>
  </div>
  <div class="p-2" *ngSwitchCase="'UNBLOCKING'">
    Déblocage par <b>{{ event.channel == "SIGNBOOK" ? '' : event.operator.email }}</b>
  </div>
  <div class="p-2" *ngSwitchCase="'PARTICIPANT_COMPLETION'">
    Mise à jour du dossier par <b>{{ event.channel == "SIGNBOOK" ? 'Trust & Sign' : event.operator.email }}</b>
  </div>
  <div class="p-2" *ngSwitchCase="'FINALIZATION'">
    Finalisation par <b>{{ event.channel == "SIGNBOOK" ? 'Trust & Sign' : event.operator.email }}</b>
  </div>
  <div class="p-2" *ngSwitchCase="'ACCEPTATION'">
    Acceptation du dossier par <b>{{ event.channel == "SIGNBOOK" ? 'Trust & Sign' : event.operator.email }}</b>
  </div>
  <div class="p-2" *ngSwitchCase="'REOPEN'">
    Ré-ouverture du dossier par <b>{{ event.operator.email }}</b>
  </div>
  <div *ngSwitchDefault>N/A</div>
</div>
