import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  // Replace markers in a template
  replaceMarkers(template: string, values: { [key: string]: string }): string {
    return template.replace(/{{(.*?)}}/g, (_, key) => values[key] || '{{' + key + '}}');
  }

  // Split message into segments of specified length (default 160)
  splitMessage(message: string, maxLength: number = 160): string[] {
    const segments: string[] = [];
    let currentIndex = 0;

    while (currentIndex < message.length) {
      let endIndex = currentIndex + maxLength;

      if (endIndex >= message.length) {
        segments.push(message.slice(currentIndex));
        break;
      }

      while (endIndex > currentIndex && message[endIndex] !== ' ') {
        endIndex--;
      }

      if (endIndex === currentIndex) {
        endIndex = currentIndex + maxLength;
      }

      segments.push(message.slice(currentIndex, endIndex).trim());
      currentIndex = endIndex + 1;
    }

    return segments;
  }
}
